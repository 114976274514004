<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      View Catalogue
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                    <v-spacer></v-spacer>
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-form ref="frm">
                  <v-card-text class="px-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Auction center</label
                        >

                        <v-autocomplete
                          :items="auctionCenter"
                          item-text="auction_center_name"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.auctionCenter"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          single-line
                          :rules="[(v) => !!v || 'Auction center is required']"
                          placeholder="Select auction center"
                          @change="resetSeason"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.auction_center_name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Season</label
                        >

                        <v-autocomplete
                          :items="season"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.season"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          @change="filterSeasonSale()"
                          single-line
                          :rules="[(v) => !!v || 'Season is required']"
                          placeholder="Select Season"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.name
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span
                          >Sale No</label
                        >

                        <v-autocomplete
                          :items="seasonSale"
                          item-value="id"
                          hide-details="auto"
                          v-model="editedItem.seasonSale"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          outlined
                          height="46"
                          single-line
                          :rules="[(v) => !!v || 'Sale No is required']"
                          placeholder="Select Sale No"
                        >
                          <template v-slot:selection="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-text
                              name="ss_name"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-caption ls-0">{{
                                item.season_sale
                              }}</span>
                            </v-text>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <!-- <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >Auctioneer</label
                        >
                        <v-autocomplete
                          :items="broker"
                          item-value="id"
                          item-text="broker_name"
                          v-model="editedItem.broker"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Auctioneer"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0"
                                >{{ item.broker_code }}-{{
                                  item.broker_name
                                }}</span
                              >
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ editedItem.broker.length - 1 }}
                              others)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          ><span class="red--text"><strong>* </strong></span>Tea
                          type</label
                        >
                        <v-autocomplete
                          :items="teaType"
                          item-value="id"
                          item-text="tea_type_name"
                          v-model="editedItem.teaType"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Tea type"
                          :rules="[(v) => !!v || 'Tea type is required']"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0">{{
                                item.tea_type_name
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ editedItem.teaType.length - 1 }}
                              others)
                            </span>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >Category</label
                        >
                        <v-autocomplete
                          :items="category"
                          item-value="id"
                          item-text="category_name"
                          hide-details="auto"
                          v-model="editedItem.category"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            border-radius-md
                            select-style
                            mt-3
                            mb-0
                          "
                          single-line
                          outlined
                          height="46"
                          placeholder="Select Category"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="2" xs="12" lg="2">
                        <label class="text-md text-typo font-weight-bolder ms-1"
                          >Grade</label
                        >
                        <v-autocomplete
                          :items="grade"
                          item-value="id"
                          item-text="grade_name"
                          v-model="editedItem.grade"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            select-style
                            mt-3
                          "
                          outlined
                          chips
                          multiple
                          height="46"
                          single-line
                          placeholder="Select Grade"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index === 0"
                              label
                              color="bg-default"
                              class="py-1 px-2 my-0"
                            >
                              <span class="text-white text-caption ls-0">{{
                                item.grade_code
                              }}</span>
                            </v-chip>
                            <span
                              v-if="index === 1"
                              class="grey--text text-caption"
                            >
                              (+{{ editedItem.grade.length - 1 }}
                              others)
                            </span>
                          </template>
                          <template v-slot:prepend-item>
                            <v-list-item
                              ripple
                              @mousedown.prevent
                              @click="toggle"
                            >
                              <v-list-item-action>
                                <v-icon
                                  :color="
                                    grade.length > 0 ? 'indigo darken-4' : ''
                                  "
                                >
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" xs="12" lg="2">
                        <label
                          class="text-md text-typo font-weight-bolder ms-1"
                        >
                          From price</label
                        >
                        <v-text-field
                          v-model.number="editedItem.fromPrice"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-3
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="From price"
                          persistent-hint
                          :rules="[
                            (v) =>
                              (!isNaN(parseFloat(v)) && v >= 0) ||
                              'Price should be a number, 0 or greater',
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" xs="12" lg="2">
                        <label
                          class="text-md text-typo font-weight-bolder ms-1"
                        >
                          To price</label
                        >
                        <v-text-field
                          v-model.number="editedItem.toPrice"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-2
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="To price"
                          :rules="[
                            (v) =>
                              (!isNaN(parseFloat(v)) && v >= 0) ||
                              'Price should be a number, 0 or greater',
                          ]"
                          persistent-hint
                        ></v-text-field>
                      </v-col> -->
                    </v-row>
                  </v-card-text>

                  <v-card-actions dense class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-light
                        px-6
                      "
                      >Clear</v-btn
                    >

                    <v-btn
                      @click="searchCatalogue()"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        px-6
                      "
                      >Search</v-btn
                    >
                  </v-card-actions>
                </v-form>
              </v-card-text>
              <v-card-text class="px-0 py-0" v-if="items.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="items"
                  class="table"
                  :footer-props="{
                    'items-per-page-options': [100, 200, 500, -1],
                  }"
                  mobile-breakpoint="0"
                  fixed-header
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="excelExport"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-primary
                          bg-success
                          py-5
                          px-5
                          shadow
                        "
                      >
                        <v-icon size="20" class="text-white mr-1">
                          fas fa-cloud-download-alt
                        </v-icon>
                        Download
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.auction_date`]="{ item }">
                    <span>
                      {{ item.auction_date.split(" ")[0] }}
                    </span>
                  </template>
                  <template v-slot:[`item.gp_date`]="{ item }">
                    <span>
                      {{ item.gp_date.split("T")[0] }}
                    </span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Allocate
          </h5></v-card-title
        >
        <v-card-text class="card-padding">
          <v-form ref="frmSave">
            <v-row>
              <v-col>
                <label
                  class="text-md text-typo font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>List
                  Name</label
                >
                <v-text-field
                  v-model="editedItem.list_name"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-3
                  "
                  dense
                  flat
                  filled
                  solo
                  height="46"
                  placeholder="Enter List Name"
                  :rules="[(v) => !!v || 'List Name is required']"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col>
                <label
                  class="text-md text-typo font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>List
                  Role</label
                >
                <v-autocomplete
                  v-model="editedItem.role"
                  :items="roles"
                  item-text="role"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-3
                    mb-0
                  "
                  placeholder="Select a Role"
                  :rules="[(v) => !!v || 'Role is Required']"
                  outlined
                  single-line
                  height="46"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions dense class="card-padding d-flex justify-end">
          <v-btn
            @click="closeDialogue"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-light
              px-6
            "
            >Cancel</v-btn
          >
          <v-btn
            @click="saveData"
            elevation="0"
            :ripple="false"
            height="43"
            dark
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  name: "Allocation",
  data() {
    return {
      page: 1,
      pageCount: 0,
      overlay: false,
      auctionCenter: [],
      seasonSale: [],
      broker: [],
      teaType: [],
      category: [],
      grade: [],
      selected: [],
      roles: [],
      season: [],
      singleSelect: true,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      items: [],
      search: "",
      editedIndex: -1,
      editedItem: {
        auctionCenter: 0,
        seasonSale: 0,
        broker: [],
        teaType: null,
        category: 0,
        grade: [],
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: "",
        season: 0, // not used in API- just to filter Sale No
      },
      defaultItem: {
        auctionCenter: 0,
        seasonSale: 0,
        broker: null,
        teaType: 0,
        category: 0,
        grade: 0,
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: "",
        season: 0, // not used in API- just to filter Sale No
      },
      headers: [
        {
          text: "Season",
          width: "150px",
          value: "season_name",
          sortable: false,
        },
        {
          text: "auction date",
          width: "150px",
          value: "auction_date",
          sortable: false,
        },
        { text: "lot no", width: "150px", value: "lot_no", sortable: false },
        {
          text: "origin",
          width: "150px",
          value: "origin",
          text: "wrap",
          sortable: false,
        },
        {
          text: "tea type",
          width: "150px",
          value: "tea_type",
          sortable: false,
        },
        {
          text: "category name",
          width: "150px",
          value: "category_name",
          sortable: false,
        },
        {
          text: "mark name",
          width: "150px",
          value: "mark_name",
          sortable: false,
        },
        {
          text: "grade name",
          width: "150px",
          value: "grade_name",
          sortable: false,
        },
        {
          text: "Item Code",
          width: "150px",
          value: "item_name",
          sortable: false,
        },
        {
          text: "Comments",
          width: "150px",
          value: "brewers_comment",
          sortable: false,
        },
        {
          text: "no packages",
          width: "150px",
          value: "no_packages",
          sortable: false,
        },
        {
          text: "gross weight",
          width: "150px",
          value: "gross_weight",
          sortable: false,
        },
        {
          text: "net weight",
          width: "150px",
          value: "net_weight",
          sortable: false,
        },
        {
          text: "period of manufacture",
          width: "150px",
          value: "period_of_manufacture",
          sortable: false,
        },
        {
          text: "base price",
          width: "150px",
          value: "base_price",
          sortable: false,
        },
        {
          text: "lot price",
          width: "150px",
          value: "price",
          sortable: false,
        },
        {
          text: "sold qty",
          width: "150px",
          value: "quantity",
          sortable: false,
        },
        {
          text: "total cost",
          width: "150px",
          value: "total_cost",
          sortable: false,
        },
        {
          text: "mark packaging comments",
          width: "150px",
          value: "mark_packaging_comments",
          sortable: false,
        },
        {
          text: "lot comments",
          width: "150px",
          value: "lot_comments",
          sortable: false,
        },
        {
          text: "lot type",
          width: "150px",
          value: "lot_type",
          sortable: false,
        },
        { text: "gp date", width: "150px", value: "gp_date", sortable: false },
        { text: "gp no", width: "150px", value: "gp_no", sortable: false },
        {
          text: "invoice no",
          width: "150px",
          value: "invoice_no",
          sortable: false,
        },
        {
          text: "package type",
          width: "150px",
          value: "package_type",
          sortable: false,
        },
        {
          text: "package no",
          width: "150px",
          value: "package_no",
          sortable: false,
        },
        {
          text: "warehouse name",
          width: "150px",
          value: "warehouse_name",
          sortable: false,
        },
        {
          text: "quality certification",
          width: "150px",
          value: "quality_certification",
          sortable: false,
        },
        {
          text: "colour of brew",
          width: "150px",
          value: "colour_of_brew",
          sortable: false,
        },
        {
          text: "age of brew in months",
          width: "150px",
          value: "age_of_brew_in_months",
          sortable: false,
        },
        {
          text: "brewers comment",
          width: "150px",
          value: "brewers_comment",
          sortable: false,
        },
        // { text: "item name", width: "150px", value: "item_name" },
        {
          text: "last sold price",
          width: "150px",
          value: "last_sold_price",
          sortable: false,
        },
        {
          text: "total sold price",
          width: "150px",
          value: "total_sold_price",
          sortable: false,
        },
        {
          text: "our sold price",
          width: "150px",
          value: "our_sold_price",
          sortable: false,
        },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    formatDate(date) {
      var d = new Date(date);
      console.log("DATES", d);
      (month = "" + (d.getMonth() + 1)),
        (day = "" + d.getDate()),
        (year = d.getFullYear());

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    // formatDate1(date) {
    //   var d = new Date(date);
    //   return d.toLocaleDateString("hi-IN");
    // },
    validate() {
      return this.$refs.frm.validate();
    },
    excelExport() {
      if (this.items.length > 0) {
        let data = this.items.map((item, i) => {
          return {
            "#": i + 1,
            Season: item.season_name,
            "Sale No": item.sale_no,
            "Auction Date": item.auction_date,

            "LOT BO": item.lot_no,
            "Tea Type": item.tea_type,
            Category: item.category_name,
            Mark: item.mark_name,
            Grade: item.grade_name,
            "No Of Packs": item.no_packages,
            "Gross Weight": item.gross_weight,
            "Net Weight": item.net_weight,
            "Invoice Weight": "",
            "Period Of Manufacture": item.period_of_manufacture,
            BasePrice: item.base_price,
            "Last Sold Price": item.last_sold_price,
            "Mark Packaging Comments": item.mark_packaging_comments,
            "Lot Type": item.lot_type,
            "GP No": item.gp_no,
            "GP Date": item.gp_date,
            "Invoice No": item.invoice_no,
            "Package Type": item.package_type,
            "Warehouse Name": item.warehouse_name,
            "Lot Price": item.price,
            "Total Cost": item.total_cost,
            "Item Code": item.item_name,
            Comments: "",
            "Buying Location": "",
            "Sold  Quantity": item.quantity,
          };
        });

        try {
          json2excel({
            data,
            name: "Catalogue Report",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.error("Excel export error:", e);
        }
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },

    async initialize() {
      try {
        this.overlay = true;
        this.auctionCenter = await apiMaster.getAuctionCenter();

        this.season = await apiMaster.getSeason();
        this.overlay = false;
      } catch (err) {
        this.showErrorAlert(err);
      }
    },

    async filterSeasonSale() {
      this.overlay = true;
      this.editedItem.seasonSale = 0;
      this.seasonSale = await apiMaster.getSeasonSaleBySeasonAndAuctionCenter(
        this.editedItem.season,
        this.editedItem.auctionCenter
      );
      this.overlay = false;
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Allocation Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },

    closeDialogue() {
      this.dialog = false;
      this.$refs.frmSave.reset();
    },
    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
        this.items = [];
        this.selected = [];
      });
    },
    reset() {
      // this.$refs.frm.reset();
      this.editedItem = {
        auctionCenter: 0,
        seasonSale: 0,
        broker: [],
        teaType: null,
        category: 0,
        grade: [],
        fromPrice: 0,
        toPrice: 0,
        role: null,
        list_name: "",
        season: 0, // not used in API- just to filter Sale No
      };
      this.$refs.frm.resetValidation();
      this.editedItem = this.defaultItem;
    },
    resetSeason() {
      this.editedItem.season = 0;
      this.editedItem.seasonSale = 0;
      this.seasonSale = [];
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidation() {
      return this.$refs.frmSave.validate();
    },

    async searchCatalogue() {
      if (this.validate()) {
        this.overlay = true;
        try {
          this.selected = [];
          this.items = [];
          let slug = "";
          slug += "auction_center=" + this.editedItem.auctionCenter;
          slug += "&season_sale=" + this.editedItem.seasonSale;
          // slug += this.editedItem.category
          //   ? "&category_id=" + this.editedItem.category
          //   : "";

          // for await (const key of this.editedItem.broker) {
          //   slug += "&auctioneer=" + key;
          // }
          // for await (const key of this.editedItem.teaType) {
          //   slug += "&tea_type_id=" + key;
          // }
          // if (this.grade.length == this.editedItem.grade.length) {
          //   for await (const key of this.editedItem.grade) {
          //     slug += "&grade=" + key.id;
          //   }
          // } else {
          //   for await (const key of this.editedItem.grade) {
          //     slug += "&grade=" + key;
          //   }
          // }

          // slug += "&from_price=" + this.editedItem.fromPrice;
          // slug += "&to_price=" + this.editedItem.toPrice;
          this.items = await api.getFilteredCatalogue(slug);
          console.log("this.items", this.items);
        } catch (err) {
          this.showErrorAlert(err.message);
        } finally {
          this.overlay = false;
        }
      }
    },

    allocate() {
      if (this.selected.length > 0) this.dialog = true;
      else {
        this.showWarningAlert("Check at least One Row");
      }
    },

    async saveData() {
      if (this.saveValidation()) {
        this.overlay = true;
        let req = {
          season_sale: this.editedItem.seasonSale,
          list_name: this.editedItem.list_name,
          role: this.editedItem.role,
          auction_center: this.editedItem.auctionCenter,
          child: [],
        };
        // let child = [];
        this.selected.forEach((el) => {
          let d = {
            cat_id: el.id,

            id: el.id,

            // item_name: el.item_name,
            lot_no: el.lot_no,
            price_valuation: el.price_valuation,
            leaf_appearance: el.leaf_appearance,
            color: el.color,
            body: el.body,
            taste: el.taste,
            comment: el.comment,
          };
          req.child.push(d);
        });

        // Object.assign(req, { child });

        await api
          .saveData(req)
          .then(() => {
            this.showSuccessAlert("Data saved");
          })
          .catch((err) => {
            this.showErrorAlert(err);
          })
          .finally(() => {
            this.overlay = false;
            this.dialog = false;
            this.reset();
          });
        //Removing allocated row from table

        this.selected.forEach((el) => {
          this.items.splice(
            this.items.findIndex((el) => el),
            1
          );
        });

        //Removing Selection
        this.selected = [];

        this.editedItem.list_name = "";
        this.editedItem.role = null;
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.editedItem.grade = [];
        } else {
          this.editedItem.grade = this.grade.slice();
        }
      });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Allocation" : "Edit Allocation";
    },
    selectAll() {
      return this.editedItem.grade.length === this.grade.length;
    },
    selectSome() {
      return this.editedItem.grade.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
};
</script>
